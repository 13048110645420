
import React from "react";
import PropTypes from "prop-types";
import "./InputField.scss";

export default function LabelledInput({
  label,
  placeholder,
  value,
  type,
  name,
  onChange,
  error,
  textarea,
  className,
  required,
}) {


  return (
    <div className={`LabelledInput ${className}`}>
      {label &&
        <div
          className="LabelledInput__Label"
          style={{
            fontWeight : required ? '500' : '300',
          }}
        >{label}</div>}
      {!textarea ? (
        <input
          className="LabelledInput__Input p-3"
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
          required={ type === "search" && required }
        />
      ) : (
        <textarea
          className="LabelledInput__Input TextArea"
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
          required
          rows={"3"}
        />
      )}
      {error && (
       <div className="InputField__Error" style={{ color: 'red' }}>{error}</div>
      )}
    </div>
  );
}

LabelledInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: () => {},
  error: PropTypes.object,
  textarea: PropTypes.bool,
};

LabelledInput.defaultProps = {
  label: "",
  placeholder: "",
  type: "text",
  value: "",
  bool: {},
  type: "text",
  textarea: false,
};


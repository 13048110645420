// import { Button } from "bootstrap";
import InputField from "../InputField"
import { useSelector } from "react-redux";
import { useState } from "react";
import './Callback.scss'
import { callBackRequest, raiseTicket } from "../../Api/contactApi";
import { toast } from "react-toastify";

const mapStateToProps = ({ cart, auth, payment }) => ({
    auth,
  });

export const Callback = ({toggleContactFormModal})=>{
    const {  auth } = useSelector(mapStateToProps);
  const { isLoggedIn, user = {} } = auth;
  const [phone_number, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");

  const [formData, setFormData] = useState({
    name : '',
    phone_number : '',
    email : '',
    description : '',
    issue : '',
    address: ''
  })

  const [errors, setErrors] = useState({
    name: "",
    phone_number: "",
    address: "",
  });

  const changeHandler = (e)=>{
    const {name, value} = e.target
    setFormData((prev)=>{
        return {
            ...prev,
            [name]: value
        }
    })

    // Reset error message
    setErrors(prev => ({
      ...prev,
      [name]: ""
    }));

    // Phone number validation
    if (name === "phone_number") {
      if (!/^\d+$/.test(value)) {
        setErrors(prev => ({
          ...prev,
          [name]: "Phone number must contain only numbers"
        }));
      }
    }
  }

  const submitHandler = async (data)=>{
    try {
      // Form validation
      debugger
      if (!formData.name) {
        setErrors(prev => ({
          ...prev,
          name: "Name is required"
        }));
        return;
      }

      if (!formData.phone_number) {
        setErrors(prev => ({
          ...prev,
          phone_number: "Phone number is required"
        }));
        return;
      }

      // API call
      formData["tenant"] = auth?.tenantDetails?.id
      const response = await callBackRequest(formData,1)
      toast.success("Thank you for your query, we will get back to you soon")
      toggleContactFormModal();
    } catch (err) {
      // handle error case
      toast.error("Sorry for inconvenience")
    }
  }
    return(
        <div className="flex flex-col gap-10">
            <div
              className="text-xl medium-bold"
              style={{
                marginBottom : '1rem'
              }}
            >
              Request for Home Delivery
            </div>

              <InputField
                type='text'
                placeholder='Name'
                name="name"
                onChange={changeHandler}
                value={formData.name}
                label={'Name'}
                required={true}
                error={errors.name}
              />

              <InputField
                type='number'
                name="phone_number"
                placeholder='Mobile Number'
                label={'Mobile Number'}
                onChange={changeHandler}
                value={formData.phone_number}
                required={true}
                error={errors.phone_number}
              />


            <InputField
              type='text'
              placeholder='Description'
              label={'Description for query (optional)'}
              name="description"
              onChange={changeHandler}
              value={formData.description}
              required={false}
            />

            <InputField
              type='text'
              placeholder='Address'
              label={'Address (optional)'}
              name="address"
              onChange={changeHandler}
              value={formData.address}
              required={false}
            />

            <div className="flex flex-row justify-center items-center gap-10">
                <button
                  onClick={toggleContactFormModal}
                  className="btn-none btn-md text-md"
                  style={{
                    border : '1px solid black'
                  }}
                >
                  Back
                </button>
                <button
                  className="btn-none btn-md text-md"
                  onClick={submitHandler}
                  style={{
                    backgroundColor : 'black',
                    color : 'white',

                  }}
                >
                  Send
                </button>
            </div>

        </div>
    )
}

import React from "react";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import CarouselThreeDots from "../../Components/Carousels/CarouselThreeDots";

import "./SingleProduct.scss";
import Image1 from "../../Assets/Images/banner/banner-choclate-image.png";
import Image2 from "../../Assets/Images/banner/banner-image.png";
import Image3 from "../../Assets/Images/banner/banner-mobile.png";
import { AddToCartButton } from "../../Components";
import { useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";
import { getBaseUrl } from "../../Lib/NetworkHandler";
import BannerImg from "../../Assets/Images/bannerImg.jpg";
import { Callback } from "../../Components/Callback/Callback";
import {Modal} from "../../Components";

const mapStateToProps = ({ auth, categories = {} }) => ({
  auth,
  categories,
});

const SingleProduct = () => {
  const {
    auth,
    categories: { list: categoryList = [] },
  } = useSelector(mapStateToProps);

  const location = useLocation();

  // Access the state passed through history.push
  const { productId } = useParams();
  const [activeCategory, setActiveCategory] = useState({});
  const [product, setProduct] = useState({});

  useEffect(async () => {
    try {
      const res = await axios.get(`${getBaseUrl()}/api/shop/product/images`, {
        params: {
          productId: productId,
        },
      });

      if (res.data) setProduct(res.data);
    } catch (err) {
      console.log("error", err);
    }
  }, [productId]);

  useEffect(async () => {
    let finishIteration = true;
    categoryList.forEach((item) => {
      console.log("cat");
      if (item.home_page && finishIteration) {
        setActiveCategory(item);
        console.log("cat", item);
        finishIteration = false;
      }
    });
  }, [categoryList]);

  const [showContactModal, setShowContactModal] = useState(false);

  const toggleContactFormModal = () => {
    setShowContactModal(!showContactModal);
  };

  return (
    <>
      <div
        className="flex responsive-flex-row"
        style={{
          paddingTop: "1rem",
          paddingBottom: "4rem",
        }}
      >
        <div className="image-section flex-1">
          <CarouselThreeDots images={product.images || [BannerImg]} />
        </div>
        <div className="product-section flex flex-col justify-center gap-075rem overflow-hidden">
          <div className="text-large text-bold-md" style = {{'font-weight': 'bold'}}>
            {product.product_name || "Title"}
          </div>
          <div
            className="text-medium text-bold-sm"

            style={{
              whiteSpace: "pre-line",
              marginTop: '1rem',
              'text-align': 'justify', 'word-break': 'break-word',  letterSpacing : '1px', paddingRight:'1rem'
            }}
          >
            {product.description || "Desc"}
          </div>
          <div className="text-medium" style={{marginTop: '1rem', fontWeight: 'bold'}}>
            <span className="ruppe">₹ </span>
            {product.price || "NA"}
          </div>
          <div />
          <div />
          <div />
          <div 
            className="button flex flex-row justify-start"
            style={{
                marginTop : '2rem'
            }}
            >
              <button
                className="button-active text-md"
                onClick={toggleContactFormModal}
                style={{
                    background : 'black',
                    color : 'white'
                }}
              >
                Ask for home delivery
              </button>
            <Modal show={showContactModal}>
              <Callback toggleContactFormModal={toggleContactFormModal} />
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleProduct;

import requestMaker from "../Lib";

export const callBackRequest = (data)=>{
    const url = `/contactus/callbackrequest/`
    const payload = data
    const params = {}
    return requestMaker(url,"post", {params, payload})
}

export const raiseTicket = (data, workflow_id)=>{
    const url = `/crm/workflows/${workflow_id}/tickets/create/`
    const payload = data;
    const params = {}
    return requestMaker(url,"post", {params, payload})
}
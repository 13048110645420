import React from "react";
import { useSelector } from "react-redux";
import { Addonbanner, TextHeading, Paragraph } from "../../Components";
import "../Terms/Terms.scss";

const mapStateToProps = ({ auth  }) => ({
  auth,
});


export default function AboutUs() {
  const { auth } = useSelector(mapStateToProps);

  return (
    <div>

      <div className="addon-container">
        <div className="addon-content">
          {
          auth?.tenantDetails?.template_details &&
          Object.entries(auth?.tenantDetails?.template_details).map(([key, values]) => {
              let heading = "";
              if (key === "about_us") {
                heading = "About The Pump";
              } else if (key == "proprietor") {
                heading = "About the Proprietor";
              } else if (key == "about_hpcl") {
                heading = "About HPCL";
              } else {
                heading = key;
                values = '';
              }

              if(typeof values === 'object' || values == '') return;

              return (
                <>
                  <Paragraph
                    id={heading}
                    heading={heading}
                    lineHeight="2rem"
                    text={values}
                  />
                </>
              );
            })
          }



          <br />

          {
          auth?.tenantDetails?.business_details &&
            Object.entries(auth?.tenantDetails?.business_details).map(
              ([key, values]) => {
                let heading = "";
                if (key === "gst") {
                  heading = "GST";
                } else if (key == "cin") {
                  heading = "CIN";
                } else if (key == "phone_number") {
                  heading = "Phone Number";
                } else if (key == "email") {
                  heading = "Email";
                } else {
                  heading = key;
                }

                return (
                  <>
                    <TextHeading
                      fontWeight="bold"
                      heading={heading}
                      fontSize=".8rem"
                    />
                    <Paragraph
                      heading=" "
                      fontWeight="bold"
                      text={`${values}`}
                    />
                    <br />
                  </>
                );
              }
            )}
        </div>
      </div>
    </div>
  );
}

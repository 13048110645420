import React from 'react'
import './Addonbanner.scss'

export default function Paragraph(props) {
  return (
    <div 
      id={props.id}
      className={"flex flex-col w-full"}
      // style={{lineHeight: `${props.lineHeight}`}}
    > 
        <div
          className='heading text-center flex-1'
          style={{
            fontWeight : '400',
            letterSpacing : '1.5px',
            color : 'black',
            alignSelf: 'flex-start',
            fontSize: 40,
            marginTop: '5%'
          }}
        >
          {
            props.heading || "Heading"
          }
        </div>
       <div 
        className='flex-1 text-medium text-bold-sm'
        style={{ 'text-align': 'justify', 'word-break': 'break-word',  letterSpacing : '1px'}}

        >
        {props.text}
       </div>
    </div>
  )
}

import React from "react";
import { useSelector } from "react-redux";
import { Addonbanner, TextHeading, Paragraph } from "../../Components";
import "../Terms/Terms.scss";

const mapStateToProps = ({ auth  }) => ({
  auth,
});


export default function Regulations() {
  const { auth } = useSelector(mapStateToProps);

  return (
    <div>

      <div className="addon-container">
        <div className="addon-content">
          {
          auth?.tenantDetails?.template_details &&
          Object.entries(auth?.tenantDetails?.template_details).map(([key, values]) => {
              let heading = "";
              if (key === "about_us") {
                heading = "About Us";
              } else if (key == "Cookie policy") {
                heading = "Cookie Policy";
              } else if (key == "privacy_policy") {
                heading = "Privacy Policy";
              } else if (key == "payment_methods") {
                heading = "Payment Methods";
              } else if (key == "delivery_services") {
                heading = "Delivery Services";
              } else if (key == "terms_and_conditions") {
                heading = "Terms and Conditions";
              } else if( key == "return_policy") {
                heading = "Return Policy"
              } else {
                heading = key;
              }

              if(typeof values === 'object' || values == '') return;

              return (
                <>
                  <Paragraph
                    id={heading}
                    heading={heading}
                    lineHeight="2rem"
                    text={values}
                  />
                </>
              );
            })
          }

          <Paragraph id="ContactUs" heading="Contact Us" lineHeight="2rem" />
          <TextHeading fontWeight="bold" heading="Address" fontSize=".8rem" />

          <Paragraph
            heading=" "
            lineHeight="2rem"
            text={`${auth.tenantDetails?.address}`}
          />

          <br />

          {
          auth?.tenantDetails?.business_details &&
            Object.entries(auth?.tenantDetails?.business_details).map(
              ([key, values]) => {
                let heading = "";
                if (key === "gst") {
                  heading = "GST";
                } else if (key == "cin") {
                  heading = "CIN";
                } else if (key == "phone_number") {
                  heading = "Phone Number";
                } else if (key == "email") {
                  heading = "Email";
                } else {
                  heading = key;
                }

                return (
                  <>
                    <TextHeading
                      fontWeight="bold"
                      heading={heading}
                      fontSize=".8rem"
                    />
                    <Paragraph
                      heading=" "
                      fontWeight="bold"
                      text={`${values}`}
                    />
                    <br />
                  </>
                );
              }
            )}
        </div>
      </div>
    </div>
  );
}

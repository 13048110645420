import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./Footer.scss";
import fbIcon from "../../Assets/Images/navbar/facebook-icon.svg";
import instaIcon from "../../Assets/Images/navbar/instagram-icon.svg";
import linkedinIcon from "../../Assets/Images/navbar/linkedIn-icon.svg";
import twIcon from "../../Assets/Images/navbar/twitter-icon.svg";
import { SOCIALMEDIA_LINKS } from "../../Assets/Constant";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import Container1 from "../Container/Container1";
const mapStateToProps = ({ categories, auth }) => ({
  categories,
  auth,
});

export const FooterSocialMediaLink = ({data}) => {
  return (
    <ul className="ul-style-none flex flex-row gap-10">
      <li>
        <a
          rel="noreferrer"
          target="_blank"
          href={data?.facebook}
        >
          <FaFacebook color="black" size={'1.3rem'} />
        </a>
      </li>

      <li>
        <a
          rel="noreferrer"
          target="_blank"
          href={data?.x}
        >
          <FaTwitter color="black" size={'1.3rem'} />
        </a>
      </li>

      <li>
        <a
          rel="noreferrer"
          target="_blank"
          href={data?.instagram}
        >
          <FaInstagram color="black" size={'1.3rem'} />
        </a>
      </li>
      <li>
        <a
          rel="noreferrer"
          target="_blank"
          href={data?.linkedin}
        >
          <FaLinkedin color="black" size={'1.3rem'} />
        </a>
      </li>
      <li>
        <a href="mailto:ziyuzabi@gmail.com">
        </a>
      </li>
    </ul>
  )

}

const Footer = ({data}) => {
  //   const { auth } = useSelector(mapStateToProps);
  //   const { showLoginPopup = false, isLoggedIn, userDetails = {} } = auth;
  const current_year = new Date().getFullYear();
  const {
    categories: { list: categoryList = [] },
    auth,
  } = useSelector(mapStateToProps);
  const { isLoggedIn, tenantDetails = {} } = auth;

  const {
    title,
    template_details,
    template_configs,
  } = tenantDetails;

  const formattedCategories = categoryList;

  const FooterCategoryList = () => {
    return (

      <div className="footer-categories">
        <div className="flex flex-col gap-5">

          <ul className="ul-style-none flex flex-col gap-5">
            {
              formattedCategories && formattedCategories.length > 0 ?
                (
                  <>
                    {
                      formattedCategories.map((category, index) => {
                        if (index <= formattedCategories.length / 2) {
                          return (
                            <li>
                              <Link
                                to={`/categories/${category.name}`}
                                key={`category.name`}
                                className="text-black text-medium regular-bold"
                              >
                                {category.name}
                              </Link>
                            </li>
                          );
                        }
                      })

                    }
                  </>
                ) :
                null
            }
          </ul>


          <ul className="ul-style-none flex flex-col gap-5">
            {
              formattedCategories && formattedCategories.length > 0 ?
                (
                  <>
                    {formattedCategories.map((category, index) => {
                      if (index > formattedCategories.length / 2) {
                        return (
                          <li>
                            <Link
                              to={`/categories/${category.name}`}
                              key={`category.name`}
                              className="text-black text-medium regular-bold"
                            >
                              {category.name}
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </>
                )
                :
              null
            }
          </ul>

        </div>
      </div>
    )
  }

{/* const HomeDelivery = () => {
 return (
      <ul className="ul-style-none flex flex-col gap-5">


        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/regulations#homeDelivery"
            className="text-black text-medium regular-bold"
          >
          Home Delivery Regulations
          </a>
        </li>
        <li></li>
      </ul>
    )
  } */}

const Facilities = () => {
 return (
      <ul className="ul-style-none flex flex-col gap-5">
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/facilities#cleanToilets"
            className="text-black text-medium regular-bold"
          >
            Clean Toilets
          </a>
        </li>

       {/* <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/facilities#homeDelivery"
            className="text-black text-medium regular-bold"
          >
            Home Delivery
          </a>
        </li> */}

        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/facilities#digitalPayments"
            className="text-black text-medium regular-bold"
          >
          Digital Payments
          </a>
        </li>
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/facilities#drinkingWater"
            className="text-black text-medium regular-bold"
          >
          Drinking Water
          </a>
        </li>
        <li></li>
      </ul>
    )
  }

const AboutUs = () => {
 return (
      <ul className="ul-style-none flex flex-col gap-5">
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/aboutUs#proprietor"
            className="text-black text-medium regular-bold"
          >
            Proprietor
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/aboutUs#hpcl"
            className="text-black text-medium regular-bold"
          >
            HPCL
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/aboutUs#aboutThePump"
            className="text-black text-medium regular-bold"
          >
          About The Pump
          </a>
        </li>
        <li></li>
      </ul>
    )
  }

  const OtherServices = () => {
 return (
      <ul className="ul-style-none flex flex-col gap-5">
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/otherServices#tyreRepair"
            className="text-black text-medium regular-bold"
          >
            Tyre Repair
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/otherServices#airCheck"
            className="text-black text-medium regular-bold"
          >
            Air Check
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/otherServices#lubeChange"
            className="text-black text-medium regular-bold"
          >
           Lube Change
          </a>
        </li>
        <li></li>
      </ul>
    )
  }

  const Products = () => {
    return (
      <ul className="ul-style-none flex flex-col gap-5">
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/featuredProducts"
            className="text-black text-medium regular-bold"
          >
            Petrol
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/featuredProducts"
            className="text-black text-medium regular-bold"
          >
            Diesel
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/featuredProducts"
            className="text-black text-medium regular-bold"
          >
           Lubricants
          </a>
        </li>
        <li></li>
      </ul>
    )
  }



  return (
    <div 
      className="footer-container relative"
      style={{
        backgroundColor : '#fff',
      }}
    >
      <Container1
        header1={title || 'Shree Balajee Filling Station'}
        header2={template_details?.footer?.description}
      >
        <div
          className="flex responsive-flex-row"
          style={{
            paddingTop : '1rem',
            gap : '30px'
          }}
        >
          <Container1
            header2={'Products'}
          >
            <div
              style={{
                paddingTop : '0.5rem'
              }}
            >
              <Products />
            </div> 
          </Container1>
           <Container1
            header2={'Other Services'}
          >
            <div
              style={{
                paddingTop : '0.5rem'
              }}
            >
              <OtherServices/>
            </div>
          </Container1>
           <Container1
            header2={'About Us'}
          >
            <div
              style={{
                paddingTop : '0.5rem'
              }}
            >
              <AboutUs/>
            </div>
          </Container1>

          <Container1
            header2={'Facilities'}
          >
            <div
              style={{
                paddingTop : '0.5rem'
              }}
            >
              <Facilities />
            </div>
          </Container1>
          {/* <Container1
            header2={'Home Delivery'}
          >
            <div
              style={{
                paddingTop : '0.5rem'
              }}
            >
              <HomeDelivery />
            </div>
          </Container1>*/}
          {/* <Container1
            header2={'Follow Us on'}
          >
            <div
              style={{
                paddingTop : '0.5rem'
              }}
            >
               <FooterSocialMediaLink data={template_configs && template_configs[0]?.config?.home?.footer?.social_media_links[0]}/>

            </div>
          </Container1> */}
        </div>
      </Container1>
      <p 
        className="text-sm text-black"
        style={{
          position : 'absolute',
          bottom : '10px',
          right : '10px',
        }}
      >
        © {current_year} {title} All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";

const Carousel = ({ 
    children, 
    className, 
    style,
    autoScroll,
    leftClick,
    rightClick,
 }) => {
  const caraRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = caraRef.current;

    // Check if there is some scrolling towards the right
    setShowLeftButton(scrollLeft > 0);

    // You can also check if scrolling towards the right end to hide right button if needed
    // const showRightButton = scrollLeft < scrollWidth - clientWidth;
  };

  const handleLeftClick = () => {
    if(leftClick){
      leftClick();
    }


    if(caraRef.current){
      const scrollLeft = caraRef.current.scrollLeft;
      const offSetWidth = caraRef.current.offsetWidth;

      if(scrollLeft >= 0){
        caraRef.current.scrollLeft -= offSetWidth;
      }

    }
  };

  const handleRightClick = () => {
    if(rightClick){
      rightClick();
    }

    if(caraRef.current){
      const scrollLeft = caraRef.current.scrollLeft;
      const offSetWidth = caraRef.current.offsetWidth;

      if(scrollLeft <= offSetWidth){
        caraRef.current.scrollLeft += offSetWidth;
      }
    }

  };

//   useEffect(() => {
//     // Auto-scroll every 3 seconds
//     if(autoScroll){
//     const intervalId = setInterval(() => {
//         const { scrollLeft, scrollWidth, clientWidth } = caraRef.current;
//         const maxScroll = scrollWidth - clientWidth;
    
//         if (scrollLeft < maxScroll) {
//             caraRef.current.scrollLeft += 250; // Scroll to the right
//         } else {
//             caraRef.current.scrollLeft = 0; // Reset to the beginning
//         }
//         }, 4000);
    
//         // Clean up the interval on component unmount
//         return () => clearInterval(intervalId);
//     }
//   }, []); // Empty dependency array ensures the effect runs only once

  return (
    <div
      className={`${className} relative w-full`}
      style={{
        overflowX: "hidden",
        overflowY: "hidden",
        whiteSpace: "nowrap",
        paddingBottom: "1rem",
        ...style,
      }}
    >
      <button
        className="absolute"
        style={{
          left: "1.3rem",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: "5",
          visibility: showLeftButton ? "visible" : "hidden",
        }}
        onClick={handleLeftClick}
      >
        <FaChevronCircleLeft size={"2rem"} color={"#FFFFFF"} />
      </button>
      <button
        className="absolute"
        style={{
          right: "1.3rem",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: "5",
        }}
        onClick={handleRightClick}
      >
        <FaChevronCircleRight size={"2rem"} color={"#FFFFFF"} />
      </button>
      <div
        ref={caraRef}
        className={`carousel relative w-full nowrap scrollbar-hidden w-full`}
        style={{
          overflowX: "scroll",
          overflowY: "hidden",
          whiteSpace: "nowrap",
          paddingBottom: "1rem",
          scrollBehavior: "smooth",
          scrollSnapType : 'x'
        }}
        onScroll={handleScroll}
      >
        {children}
      </div>
    </div>
  );
};

export default Carousel;

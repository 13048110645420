import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Banner,
  Categories,
  Foryou,
  Navbar,
  Offers,
  Help,
  Bottom,
  Install,
  WhyShop,
  Footer,
  Login,
  Overlay,
  Modal,
  Navigation,
  SearchResults,
  ProductCard,
  AddToCartButton,
} from "../../Components";

import { actionsCreator } from "../../Redux/actions/actionsCreator";
import giftIcon from "../../Assets/Images/gifs/gift.gif";
import medkit from "../../Assets/Images/medkit.png";
import girlwithmeds from "../../Assets/Images/girlwithmeds.png";
import bluebg from "../../Assets/Images/bluebg.png";

import tablets from "../../Assets/Images/tablets.png";
import playVideo from "../../Assets/Images/play-video.png";
import doctorVideo from "../../Assets/Images/doctor-video.png";

import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaPlayCircle,
} from "react-icons/fa";
import "./Home.scss";
import { welcomeModalHandler } from "../../Utils";
import Bannerv2 from "../../Components/Homepage/Bannerv2";
import Slideshow from "../../Components/Homepage/Bannerv2/Slideshow";
import Dashboard from "../dashboard";
import ProductsSlider from "../../Components/ProductCategory/Products";
import Container1 from "../../Components/Container/Container1";
import { CiSearch, CiUser } from "react-icons/ci";
import Caraousel from "../../Components/Caraousel/Caraousel";
import { useHistory } from "react-router-dom";
import useDebounce from "../../Hooks/useDebounce";
import Home2 from "../../Assets/Images/Home2.png";
import Home3 from "../../Assets/Images/Home3.png";

import Bus1 from "../../Assets/Images/Bus.svg";
import Bus2 from "../../Assets/Images/bus2.svg";
import Tent from "../../Assets/Images/tent.svg";
import Tent2 from "../../Assets/Images/hometent.svg";
import Tent3 from "../../Assets/Images/hometent2.svg";

import BannerImg from "../../Assets/Images/bannerImg.jpg";
import BannerImg2 from "../../Assets/Images/bannerbg-2.svg";
import PeopleGroup from "../../Assets/Images/peoplegroup.svg";
import facilitiesImg from "../../Assets/Images/facilitiesImg.svg";

import wildlife from "../../Assets/Images/wildlife.svg";
import bonfire from "../../Assets/Images/bonfire.svg";
import farming from "../../Assets/Images/farming.svg";
import hiking from "../../Assets/Images/hiking.svg";

import { CiCalendarDate } from "react-icons/ci";
import Button from "../../Components/Button/Button";
import { fetchAvailableProd, getAllFeaturedProd } from "../../Api/travelApi";
import { toast } from "react-toastify";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import { Callback } from "../../Components/Callback/Callback";

const mapStateToProps = ({ auth, categories = {} }) => ({
  auth,
  categories,
});

export const BannerComponent = ({ data, categoryList }) => {
  const banners = data.images ? data.images : [
    {
      uri : 'https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/WhatsApp_Image_2021-04-25_at_8.03.49_PM.jpeg'
    }
    ,
    {},
    {}
  ];

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDay, setStartDay] = useState("");
  const [endDay, setEndDay] = useState("");
  const [noOfGuest, setNoOfGuest] = useState(1);
  const [accomodation, setAccomodation] = useState(1);

  const history = useHistory();

  const sliderRef = useRef(null);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
    const day = String(today.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    setStartDate(formattedDate);
    setEndDate(formattedDate);
    setStartDay(daysOfWeek[today.getDay()]);
    setEndDay(daysOfWeek[today.getDay()]);

    const autoScroll = setInterval(() => {
      if (sliderRef.current) {
        const scrollLeft = sliderRef.current.scrollLeft;
        const offSetWidth = sliderRef.current.offsetWidth;

        const newScrollPosition = scrollLeft + offSetWidth;

        if (newScrollPosition >= sliderRef.current.scrollWidth) {
          sliderRef.current.scrollLeft = 0;
        } else {
          sliderRef.current.scrollLeft = newScrollPosition;
        }
      }
    }, 3000);

    return () => {
      clearInterval(autoScroll);
    };
  }, []);

  const handleDateChange = (event, type) => {
    const selectedDate = event.target.value;
    const dateObject = new Date(selectedDate);
    const dayOfWeek = dateObject.getDay();
    if (type == "start") {
      setStartDate(selectedDate);
      setStartDay(daysOfWeek[dayOfWeek]);
    } else {
      setEndDate(selectedDate);
      setEndDay(daysOfWeek[dayOfWeek]);
    }
  };

  const handleFetchAvailability = () => {
    history.push(`/category-availabile/${accomodation}`, {
      catId: accomodation,
      checkin: startDate,
      checkout: endDate,
    });
  };

  const [showContactModal, setShowContactModal] = useState(false);

  const toggleContactFormModal = () => {
    setShowContactModal(!showContactModal);
  };

  return (
    <div
      className="w-full h-full relative"
      id={"checkavailability"}
    >
      <div
        className="nowrap overflow-x-scroll w-full h-full scrollbar-hidden relative"
        style={{
          scrollSnapType: "x",
          scrollBehavior: "smooth",
          overflowY : 'hidden'
        }}
        ref={sliderRef}
      >
        {banners.map((banner, index) => {
          return (
            <div
              className="relative"
              style={{
                height: "86vh",
                width: "100%",
                display: "inline-block",
                scrollSnapAlign: "center",
                scrollSnapStop: "always",
                overflow: "hidden",
                backgroundImage : `url(${banner.uri ? banner.uri : BannerImg})`,
                backgroundSize : 'cover',
                backgroundRepeat : 'no-repeat',
                backgroundPosition : 'center'
              }}
            >
              <div
                className="h-full w-full absolute"
                style={{
                  top: "0",
                  left: "0",
                  backgroundColor: "#00000040",
                  zIndex: "1",
                  overflowX : 'hidden'
                }}
              >
                <div
                  className="flex repsonsive-flex-row items-end justify-center relative h-full"
                >
                  <div
                    className="space-y-10 p-10 text-center"
                    style={{
                      width : '100%',
                      padding : '10px',
                      paddingBottom: "12rem",
                      zIndex: "10",
                      whiteSpace : 'normal',
                    }}
                  >
                    <p className="text-xl text-white">{banner?.text || ""}</p>
                    <p className="text-4xl bold-xl text-white wrap">
                      {banner?.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="absolute flex responsive-flex-row gap-20 button-container-banner"
        style={{
          bottom: "0",
          zIndex: "10",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >

        <button
          className="button-active text-md"
          onClick={toggleContactFormModal}
        >
          Ask for home delivery
        </button>
      </div>

      <Modal show={showContactModal}>
        <Callback toggleContactFormModal={toggleContactFormModal} />
      </Modal>
    </div>
  );
};

export const AboutUsComponent = () => {
  const {
    auth,
    categories: { list: categoryList = [] },
  } = useSelector(mapStateToProps);

  return (
    <div className="space-y-10 flex flex-col items-center about-us-camp">
      <div className="text-center w-full flex flex-col items-center gap-15">
        <p className="text-active-camp">About</p>
        <p className="text-black text-3xl bold-xl">
          Welcome To {auth?.tenantDetails?.title || "JJCamping"}
        </p>
        <p
          className="text-black text-center"
          style={{
            width: "80%",
          }}
        >
          A day spent in a farm empowes our mind and body with clarity and
          oxygen. Every soil has a story. Every root has a table. Hear the wind,
          smell the rain and let your eyes feast on nature’s colours. Advance
          booking for the lovely winter is now open. Book your stay in the lap
          of Nature right now.
        </p>
      </div>

      <ul
        className="flex responsive-flex-row justify-around w-full ul-style-none gap-20"
        style={{
          paddingTop: "5rem",
        }}
      >
        <li className="text-center flex flex-col items-center gap-10">
          <img src={Bus1} />
          <span>30 CAMPER SITES</span>
        </li>
        <li className="text-center flex flex-col items-center gap-10">
          <img src={Bus2} />
          <span>25 CARAVAN SITES</span>
        </li>
        <li className="text-center flex flex-col items-center gap-10">
          <img src={Tent} />
          <span>50 TENT SITES</span>
        </li>
        <li className="text-center flex flex-col items-center gap-10">
          <img src={Tent2} />
          <span>10 GLAMP SITES</span>
        </li>
        <li className="text-center flex flex-col items-center gap-10">
          <img src={Tent3} />
          <span>10 CABIN HOUSES</span>
        </li>
      </ul>
    </div>
  );
};

export const CategoryComponent = ({ categoryList }) => {
  const history = useHistory();

  return (
    <div
      id="category-component"
      className="flex responsive-flex-row w-full category-wrapper-jjcamping"
      style={{
        overflowX: "hidden",
      }}
    >
      <Caraousel>
        {categoryList.map((item) => {
          return (
            <button
              className="flex-col btn-none space-y-10"
              style={{
                width: "20rem",
                display: "inline-flex",
                marginRight: "1%",
              }}
              // onClick={() => {
              //   history.push(`/categories/${item.name}`);
              // }}
            >
              <img
                src={item.image || ""}
                className=""
                style={{
                  height: "18rem",
                  aspectRatio: "1",
                  objectFit: "contain",
                }}
              />
              <span className="text-center text-active-camp text-lg bold-lg w-full">
                {item.name || "Name"}
              </span>
              <span
                className="text-center overflow-ellipsis w-full text-md"
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                {item?.description?.substring(0, 50) ||
                  "adlf adlf alskdfjlaksdjf akjf sadkjf ksdjf dksjf fkds jfkja fkjas dfjka dfjkas djfkajsdfka dfkjas dfkjaksdf jkasd fjka dfjka f".substring(
                    0,
                    50
                  )}
              </span>
            </button>
          );
        })}
      </Caraousel>
    </div>
  );
};

export const ActivityComponent = () => {
  const activityData = [
    {
      url: wildlife,
      title: "Wild Life",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas nisi nec libero fermentum.",
    },
    {
      url: bonfire,
      title: "Bonfire",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas nisi nec libero fermentum.",
    },
    {
      url: farming,
      title: "Farming",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas nisi nec libero fermentum.",
    },
    {
      url: hiking,
      title: "Hiking",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas nisi nec libero fermentum.",
    },
  ];

  return (
    <div className="flex responsive-flex-row gap-20 featured-product-list cards-margin">
      <div className="space-y-10 flex-1">
        <p className="text-active-camp">Activity</p>
        <p className="text-3xl bold-xl">
          Camp Will Be For You What You Want It To Be.
        </p>
        <img
          src={PeopleGroup}
          style={{
            marginTop: "2rem",
            height: "80%",
            aspectRatio: "1",
            objectFit: "contain",
          }}
        />
      </div>
      <div className="responsive-cards-2-1 flex-1">
        {activityData.map((item) => {
          return (
            <div className="space-y-10">
              <img
                src={item.url}
                style={{
                  height: "10rem",
                  aspectRatio: "1",
                  objectFit: "contain",
                }}
              />
              <p className="text-lg bold-lg">{item.title}</p>
              <p className="text-inactive text-sm">{item.desc}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const ProductCardCamp = ({ data, details }) => {
  const [info, setInfo] = useState({
    checkin: details?.checkin,
    checkout: details?.checkout,
    noofguest: details?.noofguest,
    id: data?.id,
  });

  const [modal, toggleModal] = useState(false);

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDay, setStartDay] = useState("");
  const [endDay, setEndDay] = useState("");
  const [noOfGuest, setNoOfGuest] = useState(1);
  const [accomodation, setAccomodation] = useState(1);

  const history = useHistory();

  useEffect(() => {
    // Set today's date as the default value
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
    const day = String(today.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    setStartDate(formattedDate);
    setEndDate(formattedDate);
    setStartDay(daysOfWeek[today.getDay()]);
    setEndDay(daysOfWeek[today.getDay()]);
  }, []);

  const handleDateChange = (event, type) => {
    const selectedDate = event.target.value;
    const dateObject = new Date(selectedDate);
    const dayOfWeek = dateObject.getDay();
    if (type == "start") {
      setStartDate(selectedDate);
      setStartDay(daysOfWeek[dayOfWeek]);
    } else {
      setEndDate(selectedDate);
      setEndDay(daysOfWeek[dayOfWeek]);
    }
  };

  const [loading, setLoading] = useState(false);

  const [available, setAvailable] = useState(false);
  const handleFetchAvailability = async () => {
    setLoading(true);

    try {
      const response = await fetchAvailableProd({
        prodId: data?.id,
        params: {
          checkin: startDate,
          checkout: endDate,
          noofguest: noOfGuest,
        },
      });

      if (response.data) {
        setAvailable(response.data.message);
      }
    } catch (err) {
      toast.error("Error in checking availability");
    }

    setLoading(false);
  };

  return (
    <>
      <Modal
        show={modal}
        onClose={() => {
          toggleModal(false);
        }}
      >
        <ul
          className="flex flex-col gap-20 ul-style-none round-10"
          style={{
            paddingTop: "5rem",
          }}
        >
          <li
            className="flex-1 flex responsive-flex-row gap-20 w-full"
            style={{
              flexGrow: "2",
            }}
          >
            <div className="flex-1">
              <span className="text-uppercase text-active-camp text-md flex flex-row items-center gap-5">
                <CiCalendarDate />
                Check In
              </span>
              {/* <input type={"date"} /> */}

              <div className="flex flex-row nowrap items-center">
                <span className="text-md bold-md">
                  {(startDay || "") + ","}
                </span>
                <input
                  type="date"
                  value={startDate}
                  onChange={(event) => {
                    handleDateChange(event, "start");
                  }}
                  className="text-md bold-md w-full flex-1"
                  style={{
                    padding: "5px",
                    border: "none",
                    outline: "none",
                  }}
                />
              </div>
            </div>
            <div className="flex-1">
              <span className="text-uppercase text-active-camp text-md flex flex-row items-center gap-5">
                <CiCalendarDate />
                Check Out
              </span>
              <div className="flex flex-row nowrap items-center">
                <span className="text-md bold-md">{(endDay || "") + ","}</span>
                <input
                  type="date"
                  value={endDate}
                  onChange={(event) => {
                    handleDateChange(event, "end");
                  }}
                  className="text-md bold-md w-full flex-1"
                  style={{
                    padding: "5px",
                    border: "none",
                    outline: "none",
                  }}
                />
              </div>
            </div>
          </li>

          <li className="flex-1 w-full">
            <span className="text-uppercase text-md text-active-camp flex flex-row items-center gap-5">
              <CiUser />
              Guests
            </span>
            <select
              value={noOfGuest}
              className="w-full text-md bold-md"
              style={{
                outline: "none",
                border: "none",
              }}
              onChange={(event) => {
                setNoOfGuest(event.target.value);
              }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
                return (
                  <option value={item}>
                    {item + " Adult" + (item > 1 ? "s" : "")}
                  </option>
                );
              })}
            </select>
          </li>
          <li className="flex-1 w-full flex flex-row justify-between gap-20">
            <Button
              active={false}
              onClick={() => {
                toggleModal(false);
              }}
              className="text-black flex-1"
            >
              Cancel
            </Button>

            <Button
              active={true}
              className="flex-1"
              loading={loading}
              onClick={handleFetchAvailability}
            >
              Check Availability
            </Button>
          </li>

          {available && (
            <>
              <hr
                style={{
                  marginTop: "1rem",
                }}
              />
              <li className="text-md bold-md text-center flex flex-col justify-center space-y-10">
                {available || "Room is available"}
                <AddToCartButton product={data}>Book Now</AddToCartButton>
              </li>
            </>
          )}
        </ul>
      </Modal>

      <div
        className="flex flex-col gap-15"
        style={{
          marginRight: "1%",
        }}
      >
        <img
          src={data.photo || ""}
          className="w-full"
          style={{
            height: "24rem",
            objectFit: "contain",
          }}
        />
        <span className="text-active-camp text-lg bold-lg w-full">
          {data.product_name || "Tent 1"}
        </span>
        <span
          className="overflow-ellipsis w-full text-md"
          style={{
            whiteSpace: "pre-line",
          }}
        >
          {data?.description?.substring(0, 50) ||
            "adlf adlf alskdfjlaksdjf akjf sadkjf ksdjf dksjf fkds jfkja fkjas dfjka dfjkas djfkajsdfka dfkjas dfkjaksdf jkasd fjka dfjka f".substring(
              0,
              50
            )}
        </span>
        <div>
          {!details ? (
            <Button
              active={true}
              onClick={() => {
                toggleModal(true);
              }}
            >
              Check Availability
            </Button>
          ) : (
            <AddToCartButton product={data}>Book Now</AddToCartButton>
          )}
        </div>
      </div>
    </>
  );
};

export const ProductCardTemp = ({ product }) => {
  const history = useHistory();

  return (
    <div
      className="space-y-20"
      style={{
        backgroundColor: "#f2f2f2",
        padding: "2rem",
      }}
      onClick={() => {
        console.log(product)
        history.push(`/product/${product?.product_name}/${product?.id}`, {
          productID: product?.id,
        });
      }}
    >
      <img
        style={{
          height: "11rem",
          objectFit: "cover",
        }}
        className="w-full"
        src={product?.photo || BannerImg}
      />

      <p className="text-lg text-black">{product?.product_name || "Diesel"}</p>

      <p className="text-md text-black" style={{ 'text-align': 'justify', 'word-break': 'break-word',}}>
        {product?.description ||
          "Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review."}
      </p>
    </div>
  );
};

export const HomePageProduct = ({ featuredProd = [1, 2] }) => {
  const history = useHistory();

  return (
    <div className="space-y-10 flex flex-col  featured-product-list cards-margin">
      <p className="text-4xl" style={{padding: '1rem'}}>Products You May Like</p>

      <div
        className="responsive-cards-3-1 w-full gap-20"
        style={{
          paddingTop: "2rem",
        }}
      >
        {featuredProd.map((item) => {
          return <ProductCardTemp product={item} />;
        })}
      </div>
    </div>
  );
};


export const OurGallery = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const galleryData = [
    {
      id: "data1",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/WhatsApp_Image_2021-04-25_at_8.03.46_PM.jpeg",
      data: "Welcome to the Shree Balaji Filling Station Gallery, where we invite you to explore our commitment to excellence and customer satisfaction. Our gallery showcases a series of images that highlight the state-of-the-art facilities and superior services that define our petrol pump.",
    },
    {
      id: "data2",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/WhatsApp_Image_2021-04-25_at_8.03.47_PM_1.jpeg",
      data: "Welcome to the Shree Balaji Filling Station Gallery, where we invite you to explore our commitment to excellence and customer satisfaction. Our gallery showcases a series of images that highlight the state-of-the-art facilities and superior services that define our petrol pump.",
    },
    {
      id: "data3",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/WhatsApp_Image_2021-04-25_at_8.03.47_PM_2.jpeg",
      data: "Welcome to the Shree Balaji Filling Station Gallery, where we invite you to explore our commitment to excellence and customer satisfaction. Our gallery showcases a series of images that highlight the state-of-the-art facilities and superior services that define our petrol pump.",
    },
    {
      id: "data4",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/WhatsApp_Image_2021-04-25_at_8.03.47_PM.jpeg",
      data: "Welcome to the Shree Balaji Filling Station Gallery, where we invite you to explore our commitment to excellence and customer satisfaction. Our gallery showcases a series of images that highlight the state-of-the-art facilities and superior services that define our petrol pump.",
    },
  ];

  return (
    <div className="flex responsive-flex-row gap-10 featured-product-list cards-margin">
      <div className="space-y-10 gallery-text-container" >
        <p className="text-3xl bold-xl" style={{padding:'1rem'}}>Gallery</p>
        {galleryData.map((item, index) => {
          return activeIndex === index && (
            <p key={item.id} className="text-md" style={{ 'text-align': 'justify', 'word-break': 'break-word', padding:'1rem'}}>
              {item.data}
            </p>
          );
        })}
      </div>

      <Caraousel
        leftClick={() => setActiveIndex(prev => prev > 0 ? prev - 1 : 0)}
        rightClick={() => setActiveIndex(prev => prev < galleryData.length - 1 ? prev + 1 : prev)}
      >
        {galleryData.map((item, index) => (
          <img
            key={item.id}
            src={item.uri}
            style={{
              paddingLeft: '0.2rem',
              width: "100%",
              height: "40vh",
              objectFit: "contain",
              scrollSnapAlign: "center"
            }}
          />
        ))}
      </Caraousel>

      <div className="absolute left-0 top-1/2 transform -translate-y-1/2">
        <button className="text-white bg-transparent border-none" onClick={() => setActiveIndex(prev => prev > 0 ? prev - 1 : 0)}>{"<"}</button>
      </div>
      <div className="absolute right-0 top-1/2 transform -translate-y-1/2">
        <button className="text-white bg-transparent border-none" onClick={() => setActiveIndex(prev => prev < galleryData.length - 1 ? prev + 1 : prev)}>{">"}</button>
      </div>
    </div>
  );
};



export const Map = ({ lat, lng, zoom }) => {
      const mapRef = useRef(null);

      useEffect(() => {
        if (!lat || !lng) {
          return;
        }

        const mapOptions = {
          center: { lat, lng },
          zoom: zoom || 12,
        };

        const map = new window.google.maps.Map(mapRef.current, mapOptions);

        new window.google.maps.Marker({
          position: { lat, lng },
          map,
          title: 'Marker',
        });

      }, [lat, lng, zoom]);

      return <div ref={mapRef} style={{ width: '100%', height: '400px' }} />;
   };


export const OurFacilities = () => {
  const facilities = [
    {
      id: "1",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/digital_payments.png",
      title: "Digital Payments",
      desc: "We make transactions seamless and secure with our digital payment options. Accepting all major digital wallets and cards, our system ensures quick and hassle-free payments. Say goodbye to the inconvenience of carrying cash and enjoy the modern convenience of digital transactions at our pump."
    },
    {
      id: "2",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/cleantoilet.jpeg",
      title: "Clean Toilets",
      desc: "Our petrol pump features impeccably clean toilets that are maintained to the highest hygiene standards. We understand the importance of a pleasant and comfortable stop during your journey, so we ensure that our restrooms are spotless and well-stocked with essentials. You can count on a fresh and inviting environment every time you visit."
    },
    {
      id: "3",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/homedelivery.jpeg",
      title: "Home Delivery",
      desc: "Experience the convenience of our home delivery service, where we bring high-quality petrol and diesel straight to your location. Whether for your business or personal needs, our reliable delivery ensures you never have to worry about refueling. Enjoy the ease and efficiency of having top-grade fuel delivered right to your doorstep. We have all the necessary licenses and regulatory approvals for ensuring the home delivery."
    },
    {
      id: "4",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/drinking_water.png",
      title: "Drinking Water",
      desc: "Stay hydrated with our clean and cool drinking water facility. Whether you need a quick sip or to refill your bottle, our water station provides safe and refreshing water to keep you energized for the road ahead. We prioritize your health and comfort by ensuring our drinking water is always pure and readily available."
    },
  ];

  const dimension = useWindowDimensions();
  console.log(dimension);

  return (
    <div id="OurFacilities" className="space-y-10 flex flex-col  featured-product-list cards-margin" style={{padding: '32px'}}>
      <p className="text-4xl">Our Facilities</p>


      <div
        className="w-full space-y-20"
        style={{
          paddingTop: "2rem",
        }}
      >
        {facilities.map((item, index) => {
          return (
            <div
              className="flex items-center gap-20"
              style={{
                flexDirection:
                  dimension.width > 400
                    ? index % 2 == 0
                      ? "row"
                      : "row"
                    : "column",
              }}
            >
              <img
                src={item.uri}
                alt="Logo"
                style={{
                  height: "7rem",
                  aspectRatio: "1",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
              <div className="space-y-20">
                <p className="text-3xl text-black">{item.title}</p>
                <p className="text-md text-black"  style={{ 'text-align': 'justify', 'word-break': 'break-word',  letterSpacing : '1px'}}>{item.desc}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const WhyUsComponent = ({ data }) => {
  return (
    <div className="p-2">
      <Container1
        index={"3"}
        header1={"Designed For You"}
        header2={
          "We've designed and curated pieces that are a cut above your average home goods, because when you level up your everyday objects, you elevate your daily rituals. Let the magic happen."
        }
        flexDirection={"responsive-flex-row items-center gap-10"}
        className="gap-20 flex flex-col"
      >
        <div className="flex responsive-flex-row gap-20">
          <img
            src={Home2}
            className="flex-1 whyus-comp-firstimg"
            style={{
              objectFit: "cover",
              height: "90vh",
            }}
          />
          <img
            src={Home3}
            className="flex-1"
            style={{
              objectFit: "cover",
              height: "90vh",
            }}
          />
        </div>
        <div className="flex responsive-flex-row gap-20">
          <img
            src={Home2}
            className="flex-1"
            style={{
              objectFit: "cover",
              height: "90vh",
            }}
          />
          <img
            src={Home3}
            className="flex-1 whyus-comp-firstimg"
            style={{
              objectFit: "cover",
              height: "90vh",
            }}
          />
        </div>
      </Container1>
    </div>
  );
};



export const Testimonials = () => {
  const testi = [
    {
      id: "1",
      uri: "https://01609b-f9.myshopify.com/cdn/shop/files/person1.jpg?v=1714049131&width=3200",
      name: "Ravi",
      desc: "I've been fueling up at this petrol pump for years, and I couldn't be happier with the service. The staff is always friendly and efficient, and the quality of the diesel is top-notch. My truck runs smoothly and gets great mileage thanks to their premium fuel. Highly recommended!"
    },
    {
      id: "2",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/testi-f-1.jpeg",
      name: "Anita",
      desc: "This petrol pump stands out for its exceptional customer service. The attendants are always polite and ready to help with anything I need. The facilities are clean and well-maintained, making every visit a pleasant experience. I trust their diesel to keep my car in perfect condition.",
    },
    {
      id: "3",
      uri: "https://01609b-f9.myshopify.com/cdn/shop/files/person2.jpg?v=1714049132&width=3200",
      name: "Manoj",
      desc: "Every time I visit this petrol pump, I'm impressed by the professionalism and courtesy of the staff. The diesel quality is consistently excellent, which is crucial for my business vehicles. Plus, the convenience store is well-stocked with everything I need for the road. Great job, team!"
    },
    {
      id: "4",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/testi-f-2.jpeg",
      name: "Priyanka",
      desc: "I appreciate the high standards of this petrol pump. From the cleanliness of the facilities to the quality of the diesel, everything is top-notch. The staff goes above and beyond to ensure a quick and pleasant refueling experience. I wouldn't go anywhere else!"
    },
    {
      id: "5",
      uri: "https://01609b-f9.myshopify.com/cdn/shop/files/person3.jpg?v=1714049131&width=3200",
      name: "Vikram",
      desc: "This petrol pump has the best service in the area. The diesel quality is excellent, which keeps my car running smoothly and efficiently. The attendants are always courteous and helpful, and the entire place is spotless. I highly recommend this petrol pump to all my friends and family."
    }
  ];

  return (
    <div
      className="space-y-10 flex flex-col  featured-product-list cards-margin"
      style={{
        backgroundColor: "#333",
        paddingTop: "1rem",
        paddingBottom: "2rem",
      }}
    >
      <p className="text-4xl text-white">Testimonials</p>

      <div
        className="w-full space-y-20 responsive-cards-3-1 gap-20"
        style={{
          paddingTop: "0.5rem",
        }}
      >
        {testi.map((item, index) => {
          return (
            <div
              className="space-y-20"
              style={{
                backgroundColor: "#444",
                padding: "2rem",
              }}
            >
              <img
                style={{
                  height: "8rem",
                  objectFit: "contain",
                }}
                className="w-full"
                src={item.uri || BannerImg}
              />

              <p className="text-xl text-white">{item?.name || "Diesel"}</p>

              <p className="text-md text-white" >
                {item?.desc ||
                  "Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review."}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    auth,
    categories: { list: categoryList = [] },
  } = useSelector(mapStateToProps);

  const { isLoggedIn, tenantDetails = {}, template_configs = [] } = auth;

  const [home, setHome] = useState({});

  const [offers, setOffers] = useState(true);
  const [subcategory, setSubcategory] = useState([]);

  const subcategoryAdd = () => {
    let temp_sub_category = [];
    categoryList.map((item) => {
      item.sub_categories &&
        item.sub_categories.map((subitem) => {
          if (subitem.home_page) {
            temp_sub_category.push(subitem);
          }
        });
    });
    setSubcategory(temp_sub_category);
  };

  useEffect(() => {
    subcategoryAdd();
  }, [categoryList]);

  const fetchCartDetails = () => {
    dispatch(actionsCreator.FETCH_CART_DETAILS());
  };

  const fetchFavouriteProducts = () => {
    dispatch(actionsCreator.FETCH_FAVOURITE_PRODUCTS());
  };

  // const [modal, setModal] = useState(true);
  const banner = tenantDetails?.template_configs
    ? tenantDetails.template_configs[0]?.config?.home.banner
    : {};
  useEffect(() => {

    if (tenantDetails.template_configs) {
      const { home } = tenantDetails?.template_configs[0]?.config;

      setHome(home);
    }

    if (isLoggedIn) {
      fetchCartDetails();
      fetchFavouriteProducts();
    }

    setActiveCategory(categoryList[0]);
  }, [auth]);

  const [featuredProd, setFeaturedProd] = useState([1, 2, 3]);

  useEffect(() => {
    const getFeaturedProd = async () => {
      try {
        const response = await getAllFeaturedProd();
        if (response.data) {
          const prodList = response.data.featured_products;
          const featList = prodList.map((item) => {
            return item.product;
          });
          console.log("features list")
          console.log(featList);
          setFeaturedProd(featList);
        }
      } catch (err) {
        // toast.error("Error in fetching featuredProducts");
        console.log("featured Prod", err);
      }
    };

    getFeaturedProd();
  }, []);

  const [activeCategory, setActiveCategory] = useState({});

  const [query, setQuery] = useState("");
  const debouncedSearch = useDebounce(query, 500);

  return (
    <div className="home relative" style={{marginTop: '1.5rem'}}>
      <BannerComponent data={banner} categoryList={categoryList} />

      {/* {
        home?.categories && 
        categoryList.length > 0
        && 
        <CategoryComponent 
          categoryList={categoryList} 
        />
      } */}

      {/* <AboutUsComponent /> */}

      {/* {home.categories && categoryList.length > 0 && (
        <CategoryComponent categoryList={categoryList} />
      )} */}

      {/* <ActivityComponent /> */}

      {/* <div
        className="w-full flex flex-row items-center justify-center"
        style={{
          height: "80vh",
          backgroundImage: `url(${BannerImg2})`,
          backgroundPosition: "center",
          objectFit: "contain",
          backgroundRepeat: "none",
          marginTop: "5rem",
        }}
      >
        <div className="flex flex-col gap-20 p-2">
          <p className="text-3xl text-white">
            A Bonfire Is Basically Just A <br /> Nightclub In The Mountains
          </p>
          <div className="flex flex-row justify-center w-full">
            <Button
              active={false}
              onClick={() => {
                document
                  .getElementById("checkavailability")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Check Availability
            </Button>
          </div>
        </div>
      </div> */}

      {/* {home?.offers && <Offers />} */}

      {/* {
        home?.featured_products 
        && 
        categoryList.length > 0
        &&
        <HomePageCategory
          categoryList={categoryList}
        />
      } */}

      {featuredProd.length > 0 && <HomePageProduct featuredProd={featuredProd} />}

      {<OurFacilities />}

      {<Testimonials />}

      {<OurGallery />}

      {home?.why_us && <WhyUsComponent />}
    </div>
  );
};

export default Home;

{
  /* <ul
        id="checkavailability"
        className="flex responsive-flex-row gap-20 ul-style-none booking-input-form round-10"
        style={{
          backgroundColor: "white",
          width: "80%",
          position: "absolute",
          top: "100%",
          overflow: "hidden",
        }}
      >
        <li
          className="flex-1 flex responsive-flex-row gap-20 w-full"
          style={{
            flexGrow: "2",
          }}
        >
          <div className="flex-1">
            <span className="text-uppercase text-active-camp text-md flex flex-row items-center gap-5">
              <CiCalendarDate />
              Check In
            </span>
            {/* <input type={"date"} /> */
}

//     <div className="flex flex-row nowrap items-center">
//       <label htmlFor="datePicker" className="text-md bold-md">
//         {(startDay || "") + ","}
//       </label>
//       <input
//         type="date"
//         id="datePicker"
//         value={startDate}
//         onChange={(event) => {
//           handleDateChange(event, "start");
//         }}
//         className="text-md bold-md w-full flex-1"
//         style={{
//           padding: "5px",
//           border: "none",
//           outline: "none",
//         }}
//       />
//     </div>
//   </div>
//   <div className="flex-1">
//     <span className="text-uppercase text-active-camp text-md flex flex-row items-center gap-5">
//       <CiCalendarDate />
//       Check Out
//     </span>
//     <div className="flex flex-row nowrap items-center">
//       <label htmlFor="datePicker" className="text-md bold-md">
//         {(endDay || "") + ","}
//       </label>
//       <input
//         type="date"
//         id="datePicker"
//         value={endDate}
//         onChange={(event) => {
//           handleDateChange(event, "end");
//         }}
//         className="text-md bold-md w-full flex-1"
//         style={{
//           padding: "5px",
//           border: "none",
//           outline: "none",
//         }}
//       />
//     </div>
//   </div>
// </li>

// <li className="flex-1 w-full">
//   <span className="text-uppercase text-md text-active-camp flex flex-row items-center gap-5">
//     <CiUser />
//     Guests
//   </span>
//   <select
//     value={noOfGuest}
//     className="w-full text-md bold-md"
//     style={{
//       outline: "none",
//       border: "none",
//     }}
//     onChange={(event) => {
//       setNoOfGuest(event.target.value);
//     }}
//   >
//     {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
//       return (
//         <option value={item}>
//           {item + " Adult" + (item > 1 ? "s" : "")}
//         </option>
//       );
//     })}
//   </select>
// </li>
// <li className="flex-1 w-full">
//   <span className="text-uppercase text-md text-active-camp flex flex-row items-center gap-5">
//     <CiCalendarDate />
//     Accomodation
//   </span>
//   <select
//     className="w-full text-md bold-md"
//     style={{
//       outline: "none",
//       border: "none",
//     }}
//     onChange={(event) => {
//       setAccomodation(event.target.value);
//     }}
//   >
//     {categoryList.map((item) => {
//       return <option value={item.id}>{item.name}</option>;
//     })}
//   </select>
// </li>
// <li className="flex-1 w-full">
//   <Button
//     className={"w-full"}
//     active={true}
//     onClick={handleFetchAvailability}
//   >
//     Check Availability
//   </Button>
// </li>

// </ul> */}

import React from "react";
import { useSelector } from "react-redux";
import { Addonbanner, TextHeading, Paragraph } from "../../Components";
import "../Terms/Terms.scss";

const mapStateToProps = ({ auth  }) => ({
  auth,
});


export default function OtherServices() {
  const { auth } = useSelector(mapStateToProps);

  return (
    <div>
      <div className="addon-container">
        <div className="addon-content">
          {
          auth?.tenantDetails?.template_details &&
          Object.entries(auth?.tenantDetails?.template_details).map(([key, values]) => {
              let heading = "";
              if (key === "tyreRepair") {
                heading = "Tyre Repair";
              } else if (key == "airCheck") {
                heading = "Air Check";
              } else if (key == "lubeChange") {
                heading = "Lube Change";
              } else {
                heading = key;
                values = '';
              }

              if(typeof values === 'object' || values == '') return;

              return (
                <>
                  <Paragraph
                    id={heading}
                    heading={heading}
                    lineHeight="2rem"
                    text={values}
                  />
                </>
              );
            })
          }
        </div>
      </div>
    </div>
  );
}

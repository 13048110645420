import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Addonbanner, TextHeading, Paragraph } from "../../Components";
import "../Terms/Terms.scss";
import { fetchAvailableProd, getAllFeaturedProd } from "../../Api/travelApi";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../../Hooks/useWindowDimensions";



const mapStateToProps = ({ auth  }) => ({
  auth,
});


export default function Facilities() {
  const OurFacilities = () => {
  const facilities = [
    {
      id: "1",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/digital_payments.png",
      title: "Digital Payments",
      desc: "We make transactions seamless and secure with our digital payment options. Accepting all major digital wallets and cards, our system ensures quick and hassle-free payments. Say goodbye to the inconvenience of carrying cash and enjoy the modern convenience of digital transactions at our pump."
    },
    {
      id: "2",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/cleantoilet.jpeg",
      title: "Clean Toilets",
      desc: "Our petrol pump features impeccably clean toilets that are maintained to the highest hygiene standards. We understand the importance of a pleasant and comfortable stop during your journey, so we ensure that our restrooms are spotless and well-stocked with essentials. You can count on a fresh and inviting environment every time you visit."
    },
    {
      id: "3",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/homedelivery.jpeg",
      title: "Home Delivery",
      desc: "Experience the convenience of our home delivery service, where we bring high-quality petrol and diesel straight to your location. Whether for your business or personal needs, our reliable delivery ensures you never have to worry about refueling. Enjoy the ease and efficiency of having top-grade fuel delivered right to your doorstep. We have all the necessary licenses and regulatory approvals for ensuring the home delivery."
    },
    {
      id: "4",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/drinking_water.png",
      title: "Drinking Water",
      desc: "Stay hydrated with our clean and cool drinking water facility. Whether you need a quick sip or to refill your bottle, our water station provides safe and refreshing water to keep you energized for the road ahead. We prioritize your health and comfort by ensuring our drinking water is always pure and readily available."
    },
  ];

  const dimension = useWindowDimensions();
  console.log(dimension);

  return (
    <div id="OurFacilities" className="space-y-10 flex flex-col  featured-product-list cards-margin"
         style={{paddingLeft: '3rem', paddingRight: '3rem', paddingBottom: '3rem'}}>
      <div
        className="w-full"
        style={{

        }}
      >
        {facilities.map((item, index) => {
          return (
            <div
              className="flex items-center gap-20"
              style={{
                flexDirection:
                  dimension.width > 400
                    ? index % 2 == 0
                      ? "row"
                      : "row"
                    : "column",
              }}
            >
              <img
                src={item.uri}
                alt="Logo"
                style={{
                  height: "5rem",
                  aspectRatio: "1",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
              <div className="space-y-20">
                <p className="text-3xl text-black">{item.title}</p>
                <p className="text-md text-black" style={{ 'text-align': 'justify', 'word-break': 'break-word',  letterSpacing : '1px'}}>{item.desc}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

  return (
    <div>
      <div className="addon-container">
        {<OurFacilities />}
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Addonbanner, TextHeading, Paragraph } from "../../Components";
import "../Terms/Terms.scss";
import { fetchAvailableProd, getAllFeaturedProd } from "../../Api/travelApi";
import { useHistory } from "react-router-dom";


const mapStateToProps = ({ auth  }) => ({
  auth,
});


export default function FeaturedProducts() {
  const { auth } = useSelector(mapStateToProps);
  const [featuredProd, setFeaturedProd] = useState([1, 2, 3]);

  useEffect(() => {
    const getFeaturedProd = async () => {
      try {
        const response = await getAllFeaturedProd();
        if (response.data) {
          const prodList = response.data.featured_products;
          const featList = prodList.map((item) => {
            return item.product;
          });
          console.log("features list")
          console.log(featList);
          setFeaturedProd(featList);
        }
      } catch (err) {
        // toast.error("Error in fetching featuredProducts");
        console.log("featured Prod", err);
      }
    };

    getFeaturedProd();
  }, []);

const ProductCardTemp = ({ product }) => {
  const history = useHistory();

  return (
    <div
      className="space-y-20"
      style={{
        backgroundColor: "#f2f2f2",
        padding: "2rem",
      }}
      onClick={() => {
        console.log(product)
        history.push(`/product/${product?.product_name}/${product?.id}`, {
          productID: product?.id,
        });
      }}
    >
      <img
        style={{
          height: "11rem",
          objectFit: "cover",
        }}
        className="w-full"
        src={product?.photo || ''}
      />

      <p className="text-lg text-black">{product?.product_name || "Diesel"}</p>

      <p className="text-md text-black" style={{ 'text-align': 'justify', 'word-break': 'break-word',  letterSpacing : '1px'}}>
        {product?.description ||
          "Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review."}
      </p>
    </div>
  );
};

const HomePageProduct = ({ featuredProd = [1, 2] }) => {
  const history = useHistory();

  return (
    <div className="space-y-10 flex flex-col  featured-product-list cards-margin">
      <p className="text-4xl" style={{padding: '1rem'}}>Our Best Selling Products</p>

      <div
        className="responsive-cards-3-1 w-full gap-20"
        style={{
          paddingTop: "2rem",
        }}
      >
        {featuredProd.map((item) => {
          return <ProductCardTemp product={item} />;
        })}
      </div>
    </div>
  );
};


  return (
    <div>
      <div className="addon-container">
        {featuredProd.length > 0 && <HomePageProduct featuredProd={featuredProd} />}
      </div>
    </div>
  );
}
